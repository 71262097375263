<!--
 * @Author: 王广婷
 * @Date: 2020-04-26 19:04:50
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-04-26 19:33:11
 -->
<template>
    <div class="content">
        <div v-if="name=='srys'" class="detail">
            <img src="@/assets/nh/srysdetail.jpg" alt="">
        </div>
        <div v-if="name=='zjwz'" class="detail">
            <img src="@/assets/nh/zjwzdetail.jpg" alt="">
        </div>
        <div v-if="name=='zzlt'" class="detail">
            <img src="@/assets/nh/zzltdetail.jpg" alt="">
        </div>
        <div v-if="name=='ecqz'" class="detail">
            <img src="@/assets/nh/ecqzdetail.jpg" alt="">
        </div>
        <div v-if="name=='jswz'" class="detail">
            <img src="@/assets/nh/jswzdetail.jpg" alt="">
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      name: ''
    }
  },
  created () {
    this.name = this.$route.query.detailid
  }
}
</script>
<style lang="stylus" scoped>
    .content{
        padding 0.41rem 0
        background #fff
    }
</style>
